import services from "../axios/dbManag";

export const detailProcedimento = (id) => {
  return services.dbManag
    .post("/sanzioni/procedimenti/view", {
      id,
    })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return false;
    });
};

export const addProcedimento = (procedimento) => {
  return services.dbManag
    .post("/sanzioni/procedimenti/add", {
      ...procedimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addSentenza = (sentenza, id_Procedimento) => {
  return services.dbManag
    .post("/sanzioni/sentenze/add", {
      ...sentenza,
      id_Procedimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addSanzione = (sanzione, id_Sentenza) => {
  return services.dbManag
    .post("/sanzioni/add", {
      ...sanzione,
      id_Sentenza,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editSentenza = (sentenza, id) => {
  return services.dbManag
    .post("/sanzioni/sentenze/edit", {
      ...sentenza,
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const editSanzione = (sanzione, id, data_Inizio) => {
  return services.dbManag
    .post("/sanzioni/edit", {
      ...sanzione,
      id,
      data_Inizio,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteSanzione = (id) => {
  return services.dbManag
    .post("/sanzioni/del", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteSentenza = (id) => {
  return services.dbManag
    .post("/sanzioni/sentenze/del", {
      id,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
